<template>
  <div class="commentBoxSp border-bottom">
    <div class="cBoxHeader">
      <el-avatar style="float: left" :src="avatarUrl"></el-avatar>
      <p>{{ comment.student.name }}</p>
      <small>{{ comment.gmtCreate }}</small>
    </div>
    <div class="commentContent"></div>
    <p>{{ comment.content }}</p>
  </div>
</template>

<script>

export default {
  name: 'CommentBoxSp',
  data () {
    return {
      avatarUrl: ''
    }
  },
  props: {
    comment: {}
  },
  created () {
    this.getAvatar()
  },
  methods: {
    getAvatar () {
      const email = this.comment.student.email
      this.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + 565843270 + '&s=100'
      if (email.substring(email.length - 7) === '@qq.com') {
        const qqId = email.substring(0, email.indexOf('@'))
        this.avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
        console.log('avatar' + this.avatarUrl)
      }
    }
  }
}
</script>

<style scoped>
.commentBoxSp {
  margin: 5px 5px;
  padding: 8px;
  line-height: 1.5;
}

.commentContent {
  margin: 3px 10px;
}

.cBoxHeader span {
  vertical-align: middle;
  font-size: 20px;
  margin: 0 8px;
}

.cBoxHeader p {
  margin: 3px 8px;
}
</style>
