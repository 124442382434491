<template>
  <div>
    <div class="container" style="margin-top: 10px">
      <el-page-header @back="goBack">
      </el-page-header>
      <div style="margin: 10px 10%">
        <h3>{{ discussion.title }}</h3>
        <p v-if="discussion.description!==null">{{ discussion.description }}</p>
        <el-divider></el-divider>
        <AddComment @doReload="reload" :discussion-id="discussion.id"></AddComment>
        <div v-for="c in comment" :key="c.id">
          <CommentBoxSp :comment="c"></CommentBoxSp>
        </div>
        <div class="block" style="text-align: center;padding-bottom: 100px">
          <span class="demonstration">调整每页显示条数</span>
          <el-pagination
            @size-change="handlePageSizeChange"
            @current-change="getAllComment"
            :current-page.sync="currentPage"
            :page-sizes="pageSizes"
            :page-size="pageSize"
            layout="sizes, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentBoxSp from '@/components/CourseMain/CommentBoxSp'
import AddComment from '@/components/AddComment'
import axios from 'axios'

export default {
  name: 'DiscussionDetails',
  components: {
    CommentBoxSp,
    AddComment
  },
  data () {
    return {
      discussion: [],
      comment: [],
      currentPage: 1,
      pageSize: 6,
      pageSizes: [6, 12, 20],
      total: 0
    }
  },
  props: {
    discussionId: Number,
    discussionName: String
  },
  created () {
    this.initDiscussion()
    this.getAllComment()
  },
  methods: {
    reload () {
      this.getAllComment()
    },
    goBack () {
      this.$router.go(-1)
    },
    initDiscussion () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/discussion/showDiscussionById',
        params: {
          id: this.$route.query.id
        }
      }).then(res => {
        if (res.data.status) {
          this.discussion = res.data.data
        }
      })
    },
    getAllComment () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/comment/showCommentsByDiscussionIdDesc',
        params: {
          discussionId: this.$route.params.id,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.data.status) {
          this.comment = res.data.data.content
          this.total = res.data.data.totalCount
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    handlePageSizeChange (val) {
      this.pageSize = val
      this.getAllComment()
    }
  }
}
</script>

<style scoped>

</style>
