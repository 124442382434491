<template>
  <div class="addDiscussion">
    <div class="contact-left">
      <!-- Contact Form -->
      <el-form id="contact-form" v-model="discussionForm">
        <div class="send-btn-div">
          <textarea id="massage" v-model="discussionForm.content" placeholder="Content"></textarea>
          <button class="send-btn" @click="addComment"><i class="el-icon-s-promotion"></i></button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AddComment',
  data () {
    return {
      discussionForm: {
        content: ''
      }
    }
  },
  props: {
    discussionId: Number,
    blogId: Number
  },
  methods: {
    addComment () {
      if (this.discussionId != null) {
        axios({
          method: 'POST',
          url: 'https://mooc.kirinnorine.top/comment/makeComment',
          params: {
            discussionId: this.discussionId,
            content: this.discussionForm.content
          }
        }).then(res => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.$emit('doReload')
          } else {
            this.$message.error(res.data.message)
          }
        })
      } else if (this.blogId != null) {
        axios({
          method: 'POST',
          url: 'https://mooc.kirinnorine.top/blogComment/createBlogComment',
          params: {
            blogId: this.blogId,
            content: this.discussionForm.content
          }
        }).then(res => {
          if (res.data.status) {
            this.$message.success(res.data.message)
            this.$emit('doReload')
          } else {
            this.$message.error(res.data.message)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.addDiscussion {
  margin: 20px auto;
}

#contact-form input {
  display: block;
  min-width: 100%;
  margin-bottom: 10px;
  height: 55px;
  padding: 7px 10px;
  background: #fff;
  border: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  font-size: 16px;
}

#contact-form textarea {
  min-width: 100%;
  height: 160px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border: none;
  line-height: initial;
  box-sizing: border-box;
  font-size: 16px;
}

.send-btn-div {
  position: relative;
  line-height: 0;
}

.send-btn {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 80px;
  background: #333;
  border: none;
  font-size: 30px;
  color: #fff;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: inset 0 0 15px 5px #333;
}

.send-btn:hover {
  background: transparent;
  color: #333;
  box-shadow: inset 0 0 0 5px #333;
}

#contact-form input:focus,
#contact-form textarea:focus {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1), inset 0 -3px #333;
  outline: none;
}

.contact-left {
  position: relative;
}
</style>
